import BaseChart from './_base';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import _ from 'lodash';

import {
  formattedTooltipOptions,
  formattedLegendOptions,
  highchartsNavigation,
  highchartsDownloadContextButton,
} from './highcharts_options';

Exporting(Highcharts);
ExportData(Highcharts);
OfflineExporting(Highcharts);
export default class StackedBar extends BaseChart {
  constructor (container, data) {
    super(container);
    this.data = data;
    this.init();
    return this.chart;
  }

  renderChart () {
    const theme = window.App.theme;
    const labelStyle = {
      color: theme.colors.defaults.chart.labelsBright,
      fontSize: '12px'
    };
    const axisStyle = {
      allowDecimals: false,
      lineColor: 'transparent',
      tickPixelInterval: 30,
      lineWidth: 0,
      minorGridLineWidth: 0,
      minorTickLength: 0,
      minPadding: 0,
      maxPadding: 0,
      tickLength: 0,
      gridLineColor: 'transparent'
    };

    const chartOptions = {
      exporting: {
        buttons: {
          contextButton: highchartsDownloadContextButton(false)
        },
        fallbackToExportServer: false,
        url: 'disabled'
      },
      navigation: highchartsNavigation(),
      chart: {
        type: 'column',
        style: {
          fontFamily: '"Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif',
          color: theme.colors.defaults.chart.labelsDim
        }
      },
      title: { text: this.data.title.text },
      tooltip: formattedTooltipOptions(true, 1),
      plotOptions: {
        column: {
          stacking: 'percent',
          groupPadding: 0.1,
          borderWidth: 0,
          groupZPadding: 0,
          pointPadding: 0.05
        }
      },
      // colors: this.data.colors,
      xAxis: _.extend({
        title: {
          text: 'Financial period'
        },
        labels: {
          style: labelStyle
        },
        categories: this.data.xAxis.categories,
        tickColor: '#FFFFFF'
      }, axisStyle),
      yAxis: jQuery.extend({
        reversedStacks: false,
        title: {
          text: null
        },
        labels: {
          style: labelStyle,
          formatter: function () {
            return this.value + '%';
          }
        }
      }, axisStyle),
      credits: {
        enabled: false
      },
      series: this.data.series,
      legend: formattedLegendOptions(this.data.series, true)
    };
    this.chart = Highcharts.chart(this.container, chartOptions);
    return this.chart;
  }
}
