import BaseChart from './_base';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import {
  formattedLegendOptions,
  formattedTooltipOptions,
  highchartsNavigation,
  highchartsDownloadContextButton,
} from './highcharts_options';

Exporting(Highcharts);
ExportData(Highcharts);
OfflineExporting(Highcharts);

export default class BarLine extends BaseChart {
  constructor (container, props) {
    super(container, props);
    this.props = props;
    this.options = _.extend({
      labelRotation: -45,
      showContextButton: false,
      showTitle: false,
      showGridLines: false,
      showAxisLines: false,
      showLegend: true,
      dimensions: {}
    }, props.options);
    this.init();
    return this.chart;
  }

  renderChart () {
    const theme = window.App.theme;
    const _options = this.options;
    const series = [];
    this.props.bars.forEach((bar, index) => {
      series.push({
        yAxis: 1,
        name: bar.title,
        data: bar.data,
        type: 'column',
        color: this.props.colors[index + 1],
        tooltip: {
          pointFormat: bar.pointFormat,
          valueDecimals: bar.valueDecimals
        },
        custom: bar.custom,
        legendIndex: 1
      });
    });
    series.push(
      {
        yAxis: 0,
        name: this.props.line.title,
        data: this.props.line.data,
        type: 'spline',
        color: this.props.colors[0],
        tooltip: {
          pointFormat: this.props.line.pointFormat,
          valueDecimals: this.props.line.valueDecimals
        },
        custom: this.props.line.custom,
        legendIndex: 0
      }
    );

    const chartOpts = {
      exporting: {
        buttons: {
          contextButton: highchartsDownloadContextButton(_options.showContextButton)
        },
        fallbackToExportServer: false,
        url: 'disabled'
      },
      navigation: highchartsNavigation(),
      title: { text: _options.showTitle ? this.props.title : null },
      chart: {
        zoomType: 'xy',
        marginTop: 50,
        height: _options.dimensions.height || this.container.clientHeight || null,
        width: _options.dimensions.width || this.container.clientWidth || null,
        style: {
          fontFamily: '"Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif',
          color: theme.colors.defaults.chart.labelsDim
        }
      },
      colors: ['#5BC9E6'],
      tooltip: formattedTooltipOptions(),
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            radius: 5
          }
        }
      },
      xAxis: [
        {
          categories: this.props.categories,
          crosshair: true,
          lineWidth: 0,
          lineColor: 'transparent',
          gridLineColor: 'transparent',
          minorGridLineWidth: 0,
          minorTickLength: 0,
          tickLength: 0,
          labels: {
            style: {
              color: theme.colors.defaults.chart.labelsDim
            }
          },
          min: 0,
          maxPadding: 0.05
        }
      ],
      yAxis: [
        {
          allowDecimals: false,
          title: {
            text: this.props.line.axis.title,
            align: 'high',
            textAlign: 'left',
            reserveSpace: false,
            offset: 0,
            rotation: 0,
            useHTML: false,
            y: this.props.line.axis.y || -15,
            x: this.props.line.axis.x || 0,
            style: {
              color: theme.colors.defaults.chart.labelsDim,
              whiteSpace: 'nowrap'
            }
          },
          labels: {
            format: this.props.line.axis.format,
            style: {
              color: theme.colors.defaults.chart.labelsDim
            }
          },
          maxPadding: 1
        }, {
          title: {
            text: this.props.bars[0].axis.title,
            align: 'high',
            textAlign: 'right',
            reserveSpace: false,
            offset: 0,
            rotation: 0,
            useHTML: false,
            y: this.props.bars[0].axis.y || -15,
            x: this.props.bars[0].axis.x || 0,
            style: {
              color: theme.colors.defaults.chart.labelsDim,
              whiteSpace: 'nowrap'
            }
          },
          labels: {
            format: this.props.bars[0].axis.format,
            style: {
              color: theme.colors.defaults.chart.labelsDim
            }
          },
          opposite: true,
          min: 0,
          maxPadding: 0.05
        }
      ],
      series: series,
      legend: formattedLegendOptions(series, _options.showLegend),
    };
    this.chart = Highcharts.chart(this.container, chartOpts);
  }
}
