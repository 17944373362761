import BaseChart from './_base';
import Highcharts from 'highcharts';
import defaultsDeep from 'lodash/defaultsDeep';
import More from 'highcharts/highcharts-more';
import {
  formattedLegendOptions,
  formattedTooltipOptions,
} from './highcharts_options';
More(Highcharts);

export default class BoxPlot extends BaseChart {
  constructor (container, props) {
    super(container, props);
    this.props = props;

    this.chart = null;
    this.options = this.props.options;
    this.data = this.props.data;
    this.customLegendId = this.props.customLegendId;

    this.init();
    return this.chart;
  }

  updateCustomLegend (point) {
    // Uncomment these if you want the legend symbols to match their actual rendered width in the chart.
    // let whiskerWidth = point.customTopWhisker.getBBox().width
    // let medianWidth  = point.customMedian.getBBox().width
    // let boxWidth     = point.box.getBBox().width

    $(`#${this.customLegendId} .whisker-top-symbol`).css({
      height: this._elementDimensions.whiskerWidth,
      backgroundColor: this._colors.whiskerTop
      // width: `${whiskerWidth}px`
    });

    $(`#${this.customLegendId} .whisker-bottom-symbol`).css({
      height: this._elementDimensions.whiskerWidth,
      backgroundColor: this._colors.whiskerBottom
      // width: `${whiskerWidth}px`
    });

    $(`#${this.customLegendId} .median-symbol`).css({
      height: this._elementDimensions.medianWidth,
      backgroundColor: this._colors.median
      // width: `${medianWidth}px`
    });

    $(`#${this.customLegendId} .box-symbol`).css({
      backgroundColor: this._colors.boxFill,
      borderColor: this._colors.boxLine,
      height: '20px',
      width: '15px'
      // width: `${boxWidth}px`
    });

    this.props.extraSeries?.forEach((element, index) => {
      const diameter = element.radius * 2;
      $(`#${this.customLegendId} .extra-series-${index}-circle-symbol`).css({
        backgroundColor: element.color,
        height: diameter,
        width: diameter,
        borderRadius: element.radius
      });
    });
  }

  addCustomMedian (series, point) {
    if (this.props.disableCustomMedian) { return ;}

    if (!point.medianShape) { return; }

    const pathData = point.medianShape.d.split(' '); // ['M', '19', '153.5', 'L', '56', '153.5']
    const x1 = Number(pathData[1]); // '19'
    const x2 = Number(pathData[4]); // '56'
    const y = Number(pathData[2]); // '153.5'
    const l = x2 - x1;
    const nl = l * this._elementDimensions.medianLength;

    if (nl == 0) {
      return;
    }

    const m = (l - nl) / 2;
    const nx1 = x1 + m;
    const nx2 = x2 - m;

    const attr = {
      d: `M ${nx1} ${y} L ${nx2} ${y}`,
      'stroke-width': this._elementDimensions.medianWidth,
      stroke: this._colors.median
    };

    if (point.customMedian) {
      point.customMedian.attr(attr);
    } else {
      point.customMedian = series.chart.renderer
        .path()
        .attr(attr)
        .add(series.group);
    }
  }

  // Render custom top whisker overtop of default whisker so we can have a different color.
  addCustomTopWhisker (series, point) {
    if (!point.whiskers) {
      return;
    }

    const paths = point.whiskers.d.split('M');
    const attr = {
      d: 'M' + paths[1], // path[1] = x1
      'stroke-width': this._elementDimensions.whiskerWidth,
      stroke: this._colors.whiskerTop
    };

    // Hide if whisker is inside box.
    if (point.high <= point.q3) {
      return;
    }

    if (point.customTopWhisker) {
      point.customTopWhisker.attr(attr);
    } else {
      point.customTopWhisker = series.chart.renderer
        .path()
        .attr(attr)
        .add(series.group);
    }
  }

  renderChart () {
    const theme = window.App.theme;
    const _options = this.options;

    // Store these methods for use later (this is to work around `this` scope weirdness)
    const _addCustomTopWhisker = this.addCustomTopWhisker.bind(this);
    const _addCustomMedian = this.addCustomMedian.bind(this);
    const _updateCustomLegend = this.updateCustomLegend.bind(this);

    // Calculate medianLength multiplier w/defaults.
    const defaultMedianLength = 0.5;
    const medianLength = 'medianLength' in _options.inrevCustom ? _options.inrevCustom.medianLength : defaultMedianLength;
    const _medianLength = (medianLength <= 1 && medianLength >= 0) ? medianLength : defaultMedianLength;

    const _elementDimensions = this._elementDimensions = { // Store dimensions on `this` for convenience.
      whiskerLength: _options.inrevCustom.whiskerLength || '25%',
      whiskerWidth: _options.inrevCustom.whiskerWidth || 3,
      medianLength: _medianLength,
      medianWidth: _options.inrevCustom.medianWidth || 3
    };

    const _colors = this._colors = { // Store colors on `this` for convenience.
      boxFill: _options.colors?.boxFill || '#b6b6b6',
      boxLine: _options.colors?.boxLine || '#979797',
      stem: _options.colors?.stem || '#0033a0',
      median: _options.colors?.median || '#fc4c02',
      whiskerBottom: _options.colors?.whiskerBottom || '#6CC24A',
      whiskerTop: _options.colors?.whiskerTop || '#59CBE8',
      meanLine: _options.colors?.meanLine || '#59CBE8'
    };

    // if this.props.series is provided it will be sent as is to highcharts,
    // ignoring this.props.data and this.props.extraSeries
    let series = [];
    if(!this.props.series) {
      series = [{
        name: this.props.title,
        data: this.props.data
      }];

      this.props.extraSeries?.forEach(element => {
        series.push({
          type: 'scatter',
          data: element.data,
          marker: {
            fillColor: element.color,
            radius: element.radius
          }
        });
      });
    } else {
      series = this.props.series;
    }

    const chartOptions = defaultsDeep(_options, {
      tooltip: formattedTooltipOptions(this.props.tooltipEnabled),
      chart: {
        type: 'boxplot',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        style: {
          fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
          color: theme.colors.defaults.chart.labelsBright
        },
        events: {
          render: function (e) {
            const series = this.series[0];

            series.points.forEach(function (point) {
              _addCustomTopWhisker(series, point);
              _addCustomMedian(series, point);
            }, this);

            _updateCustomLegend(series.points[1]);
          }
        },
        height: _options.dimensions?.height || this.container.clientHeight || null,
        width: _options.dimensions?.width || this.container.clientWidth || null,
        marginTop: _options.margin?.top || null,
        marginRight: _options.margin?.right || null,
        marginBottom: _options.margin?.bottom || null,
        marginLeft: _options.margin?.left || null
      },
      xAxis: {
        crosshair: false,
        lineWidth: 0,
        lineColor: 'transparent',
        gridLineColor: 'transparent',
        minorGridLineWidth: 0,
        minorTickLength: 0,
        tickLength: 0,
        labels: {
          rotation: _options.inrevCustom.xAxisLabelRotation,
          style: {
            color: theme.colors.defaults.chart.labelsDim
          }
        }
      },
      yAxis: {
        title: {
          text: _options.inrevCustom.yAxisTitle,
          style: {
            color: theme.colors.defaults.chart.labelsBright
          },
          margin: 5
        },
        allowDecimals: true,
        crosshair: false,
        lineWidth: 0,
        lineColor: 'transparent',
        gridLineColor: 'transparent',
        minorGridLineWidth: 0,
        minorTickLength: 0,
        tickLength: 0,
        tickInterval: _options.yAxis?.tickInterval || 1,
        min: _options.yAxis?.min,
        max: _options.yAxis?.max,
        maxPadding: 0.01,
        minPadding: 0.01,
        labels: {
          style: {
            color: theme.colors.defaults.chart.labelsDim
          },
          format: _options.yAxis?.labels?.format || '{value}%'
        },
        plotLines: (Number.isFinite(_options.inrevCustom.mean) && [{
          value: _options.inrevCustom.mean,
          color: _colors.meanLine,
          width: 1
        }])
      },
      title: {
        text: _options.showTitle && this.props.title ? this.props.title : null,
        verticalAlign: 'middle',
        useHTML: true,
        floating: true
      },
      plotOptions: {
        series: {
          animation: _options.animation,
          events: {
            legendItemClick: (event) => {
              // This allows you to disable/enable series in groups, using toggleGroup to define each group.
              if(!event?.target?.options?.toggleGroup) { return; }

              event.preventDefault();
              let visible = event.target.visible;
              let relatedSeries = this.chart.legend.allItems
                .filter((i) => i.options.toggleGroup == event.target.options.toggleGroup);
              relatedSeries.forEach(item => item.setVisible(!visible));
            }
          }
        },
        boxplot: {
          fillColor: _colors.boxFill,
          lineWidth: 2,
          lineColor: _colors.boxLine,
          medianColor: 'transparent',
          medianWidth: 0,
          stemColor: _colors.stem,
          stemWidth: 1,
          whiskerColor: _colors.whiskerBottom,
          whiskerLength: _elementDimensions.whiskerLength,
          whiskerWidth: _elementDimensions.whiskerWidth
        }
      },
      credits: {
        enabled: false
      },
      series: series,
      exporting: {
        buttons: {
          contextButton: { enabled: false }
        }
      },
      legend: formattedLegendOptions(_options?.series, _options.showLegend),
    });
    this.chart = Highcharts.chart(this.container, chartOptions);
    return this.chart;
  }
}
