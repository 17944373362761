import defaultsDeep from 'lodash/defaultsDeep';

export function highchartsNavigation () {
  return {
    buttonOptions: {
      verticalAlign: 'bottom',
      y: -10,
      theme: {
        'stroke-width': 1,
        stroke: '#E1E1E1',
        r: 3,
        padding: 12,
        states: {
          hover: {
            fill: '#FFFFFF'
          },
          select: {
            fill: '#FFFFFF'
          }
        }
      }
    }
  };
}

export function highchartsLegend (enabled = true, alignment = 'center', useHTML = true, cords = { x: 0, y: 0 }, symbolWidth = 16) {
  const theme = window.App.theme;
  return {
    enabled: enabled,
    layout: 'horizontal',
    align: alignment,
    useHTML: useHTML,
    itemStyle: {
      fontSize: '13px',
      fontFamily: 'Arial',
      color: theme.colors.defaults.chart.labelsDim
    },
    x: cords.x,
    y: cords.y,
    symbolWidth: symbolWidth
  };
}

export function highchartsDownloadContextButton (enabled = true, menuItems = ['downloadPDF', 'downloadXLS']) {
  return {
    menuItems: menuItems,
    text: 'Print / Save',
    symbol: '',
    enabled: enabled
  };
}

export function highchartsTooltipOptions (enabled = true) {
  return {
    enabled: enabled,
    shared: true,
    useHTML: true,
    shadow: false,
    outside: true,
  };
}


export function formattedTooltipOptions(enabled=true, defaultValuedecimals=2, showAllSeries=false) {
  return {
    enabled: enabled,
    outside: true,
    useHTML: true,
    formatter: function () {
      let tooltipSpan = (name, value, color, format, point, units, valuedecimals) => {
        switch(format) {
          case 'int':
            value = `${Math.round(value)}`;
          break;
          case 'percentage':
            value = `${value.toFixed(valuedecimals)}%`;
          break;
          case 'currency':
            value = parseFloat(`${value}`);
            value = new Intl.NumberFormat('en-US', {
              style: 'decimal',
              useGrouping: true
            }).format(value.toFixed(valuedecimals)).replace(/,/g, ' ');
            break;
        }
        units = units ? ` ${units}` : ''
        let percentage = point.percentage ? ` (${Math.round(point.percentage)}%)` : ''; // Used for stacked bars
        return `<span class='b-chart-labels__label b-chart-labels__label--flex b-chart-labels__label--custom-swatch'>
          <span class='b-chart-labels__swatch' style="background:${color}" > </span>
          <span>${name}:&nbsp;</span>
          <span class='b-chart-labels__value'>${value}${units}${percentage}</span>
        </span>`
      }
      let renderStack = (stack) => {
        const sortedHtmlLines = {};
        let filteredSeries = this.series.chart.series.filter((series) => {
          return (series.visible || series.options?.custom?.tooltipOnly) && stack == series.options?.stack;
        });
        filteredSeries.forEach((series, seriesIndex) => {
          let filteredData = series.data.filter((point) => point.category == this.x);
          filteredData.forEach(point => {
            let custom = point.series.options?.custom;
            let customTooltipOrder = custom?.tooltipOrder;
            let format = custom?.tooltipFormat || 'percentage';
            let valuedecimals = series.tooltipOptions?.valueDecimals ?? defaultValuedecimals;
            if(point.q1 && point.q3) {
              // box plot contains q1, q2, q3, q4 ...
              // q3 is the upper quartile
              // q1 is the lower quartile
              // Add the following to the series data to customize the label and the order
              // custom: {
              //   tooltipOrder: { q1: 4, q3: 2 },
              //   tooltipName: { q1: 'Lower quartile', q3: 'Upper quartile' },
              // },
              let upperQuartileOrder = customTooltipOrder?.q3 ?? point.series.options.legendIndex ?? seriesIndex;
              let lowerQuartileOrder = customTooltipOrder?.q1 ?? point.series.options.legendIndex ?? seriesIndex;
              let upperQuartileName = custom?.tooltipName?.q3 ?? series.name;
              let lowerQuartileName = custom?.tooltipName?.q1 ?? series.name;
              sortedHtmlLines[upperQuartileOrder] ??= [];
              sortedHtmlLines[lowerQuartileOrder] ??= [];
              sortedHtmlLines[upperQuartileOrder].push(tooltipSpan(upperQuartileName, point.q3, series.color, format, point, custom?.units, valuedecimals));
              sortedHtmlLines[lowerQuartileOrder].push(tooltipSpan(lowerQuartileName, point.q1, series.color, format, point, custom?.units, valuedecimals));
            } else {
              // Add the following to the series data to customize the order
              // custom: { tooltipOrder: 3 },
              if (point.y) {
                let order = customTooltipOrder ?? point.series.options.legendIndex ?? seriesIndex;
                sortedHtmlLines[order] ??= [];
                sortedHtmlLines[order].push(tooltipSpan(custom?.tooltipName ?? series.name, point.y, series.color, format, point, custom?.units, valuedecimals));
              }
            }
          });
        });
        return `<span style="font-size: 10px; font-weight: bold;">${this.x}${stack ? ` - ${stack}` : ''}</span><br />`+
          Object.keys(sortedHtmlLines)
            .map((order) => [order, sortedHtmlLines[order]])
            .sort((pair) => pair[0])
            .map((pair) => pair[1].join(''))
            .join('');
      };
      let stacks = [...new Set(this.series.chart.series?.map(i => i.options.stack))];
      let html = '';
      if(stacks.length == 2 && showAllSeries) {
        // Show both stacks
        html = renderStack(stacks[0]) + '<br />' +renderStack(stacks[1]);
      } else {
        // Show only the items from the hover facet set (stack)
        html = renderStack(this.point.series.options?.stack);
      }
      return html;
    }
  }
}

export function formattedLegendOptions(series, enabled=true, symbolWidth=0) {
  let legendOptions = defaultsDeep(
    highchartsLegend(enabled), {
      symbolRadius: 0,
      useHTML: true,
      labelFormatter: function () {
        if (this.options?.custom?.tooltipOnly) {
          // Add the following to the series data:
          // `marker: { enabled: false }, custom: { tooltipOnly: true },`
          // This custom will hide the series from the legend and chart,
          // making it available only on the tooltip.
          return;
        }
        let legendIcon;
        if (this.visible) {
          legendIcon = ' <i class="icon--sm icon-inrev-legend-show" />';
        } else {
          legendIcon = ' <i class="icon--sm icon-inrev-legend-hide" />';
        }
        return this.name + legendIcon;
      }
    });

  let stacks = [...new Set(series?.map(i => i.stack))];
  if(stacks.length == 2) {
    let maxNameLength = Math.max(...series?.map(i => i.name.length));
    let width = maxNameLength * 12.5 + 125 + symbolWidth;
    legendOptions = defaultsDeep(legendOptions, {
      title: {
        text: `<div style="display:flex; width: ${width}px; text-align: center;">
                  <span style="flex: 50%;">${stacks[0]}</span>
                  <span style="flex: 50%;">${stacks[1]}</span>
              </div>`
      },
      width: width,
      itemWidth: width / 2,
      itemStyle: {
        fontWeight: "normal",
      },
    });
  }
  return legendOptions;
}
